export enum ServiceCustomerStatus{
  PROPOSED = "Non avviato",
  ACTIVE = "Attivo",
  ARCHIVED = "Archiviato",
  CANCELED = "Annulato",
}
export interface ServiceCustomer {
  _id: string;
  fee: number;
  arrayTranche: any[];
  tranche: any;
  customerId: string;
  serviceId: string;
  status: "PROPOSED" | "ACTIVE" | "ARCHIVED" | "CANCELED";
  broker: string;
  relatives?: ServiceCustomerRelative[];
}

export interface ServiceCustomerRelative {
  _id: string;
  relativeId: string;
  relation: string;
}

export interface ServiceCustomerRelativeInfo {
  _id: string;
  first_name: string;
  last_name: string;
  cf: string;
}
